const Link = {
	MuiLink: {
		styleOverrides: {
			root: {
				color: 'currentColor',
			},
		},
	},
}

export default Link
