import Accordion from '@/theme/components/accordion'
import BHBreadcrumbs from '@/theme/components/bh-breadcrumbs'
import BHCrossSell from '@/theme/components/bh-crosssell'
import BHHeader from '@/theme/components/bh-header'
import BHRelated from '@/theme/components/bh-related'
import BHRelatedAttributes from '@/theme/components/bh-related-attributes'
import BHTileBase from '@/theme/components/bh-tile-base'
import BHUpsells from '@/theme/components/bh-upsells'
import Button from '@/theme/components/button'
import CssBaseline from '@/theme/components/css-baseline'
import Dialog from '@/theme/components/dialog'
import Checkbox from '@/theme/components/form/checkbox'
import Dropdown from '@/theme/components/form/dropdown'
import Input from '@/theme/components/form/input'
import IconButton from '@/theme/components/icon-button'
import Link from '@/theme/components/link'
import SvgIcon from '@/theme/components/svg-icon'
import Typography from '@/theme/components/typography'
import { fontSize, fonts, htmlFontSize } from '@/theme/fonts'
import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles'
import { createTheme } from '@mui/system'

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = 'var(--font-montserrat)'
const fontFamilyAlternative = fontFamily

const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#000000',
			white: '#ffffff',
		},
		primary: {
			main: '#000000',
			light: '#333333',
			dark: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ffffff',
			light: '#fcfcfc',
			dark: '#fcfcfc',
			contrastText: '#000000',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#FFFFFF',
			bgMiddle: '#FFFFFF',
			bgMiddleBottom: '#FFFFFF',
			bgBottom: '#FFFFFF',
			border: 'rgba(236, 236, 236, 1)',
			color: '#000000',
		},
		grey: {
			lighter: '#F7F7F7',
			light: '#E1E1E1',
			medium: '#D9D9D9',
			main: '#9F9F9F',
			dark: '#373737',
			disabled: '#ACACAC',
			active: '#ECECEC',
		},
		text: {
			disabled: '#fff',
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		lineHeight: muiTheme.typography.pxToRem(20),
		headlinebig: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(40),
			lineHeight: muiTheme.typography.pxToRem(40),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(56),
				lineHeight: muiTheme.typography.pxToRem(40),
			},
		},
		headlinemedium: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: muiTheme.typography.pxToRem(40),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
				lineHeight: muiTheme.typography.pxToRem(40),
			},
		},
		headlinesmall: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: muiTheme.typography.pxToRem(29),
		},
		subheadline1: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: muiTheme.typography.pxToRem(40),
		},
		subheadline2: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: muiTheme.typography.pxToRem(40),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16),
				lineHeight: muiTheme.typography.pxToRem(40),
			},
		},
		h1: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(32),
			lineHeight: muiTheme.typography.pxToRem(40),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
				lineHeight: muiTheme.typography.pxToRem(40),
			},
		},
		h2: {
			...fonts.montserratLight,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: muiTheme.typography.pxToRem(30),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
				lineHeight: muiTheme.typography.pxToRem(32),
			},
		},
		h3: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: muiTheme.typography.pxToRem(30),
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
				lineHeight: muiTheme.typography.pxToRem(30),
			},
		},
		h4: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: muiTheme.typography.pxToRem(28),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
				lineHeight: muiTheme.typography.pxToRem(28),
			},
		},
		h5: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: muiTheme.typography.pxToRem(24),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(18),
				lineHeight: muiTheme.typography.pxToRem(24),
			},
		},
		h6: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: muiTheme.typography.pxToRem(19.5),
		},
		subtitle1: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: muiTheme.typography.pxToRem(24),
		},
		subtitle2: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: muiTheme.typography.pxToRem(20),
		},
		body1: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: muiTheme.typography.pxToRem(20),
		},
		body2: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: muiTheme.typography.pxToRem(24),
		},
		body3: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: muiTheme.typography.pxToRem(14),
		},
		body4: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: muiTheme.typography.pxToRem(24),
			textTransform: 'uppercase',
		},
		button: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: muiTheme.typography.pxToRem(17),
			letterSpacing: 0.1,
			textTransform: 'uppercase',
		},
		caption: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(10),
			lineHeight: muiTheme.typography.pxToRem(16),
		},
		overline: {
			...fonts.montserrat,
		},
		copyright: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(10),
			lineHeight: muiTheme.typography.pxToRem(16),
		},
		checkbox: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: muiTheme.typography.pxToRem(20),
		},
		label: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: muiTheme.typography.pxToRem(20),
		},
	},
	scrollbar: `
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: var(--color-brand, #3d3935) #F7F7F7; // thumb, track
		::-webkit-scrollbar {
			width: 7px;
			height: 5px;
		}
		//track
		::-webkit-scrollbar-track {
			background: #F7F7F7; // grey.lighter
			border-radius: 3.5px;
		}
		//handle
		::-webkit-scrollbar-thumb {
			background: var(--color-brand, #3d3935);
			border-radius: 3.5px;
		}
		//handle on hover
		::-webkit-scrollbar-thumb:hover {
			background: #373737; // grey.dark
		}
	`,
	scrollbarJsx: {
		scrollbarWidth: 'auto',
		scrollbarColor: 'var(--color-brand, #3d3935) #F7F7F7', // thumb, track
		'::-webkit-scrollbar': {
			width: 7,
		},
		//track
		'::-webkit-scrollbar-track': {
			background: '#F7F7F7', // grey.lighter
			borderRadius: 3.5,
		},
		//handle
		'::-webkit-scrollbar-thumb': {
			background: 'var(--color-brand, #3d3935)',
			borderRadius: 3.5,
		},
		//handle on hover
		'::-webkit-scrollbar-thumb:hover': {
			background: '#373737', // grey.dark
		},
	},
	components: {
		...Accordion,
		...BHBreadcrumbs,
		...BHCrossSell,
		...BHHeader,
		...BHRelated,
		...BHRelatedAttributes,
		...BHTileBase,
		...BHUpsells,
		...Button,
		...Checkbox,
		...CssBaseline,
		...Dialog,
		...Dropdown,
		...IconButton,
		...Input,
		...Link,
		...SvgIcon,
		...Typography,
	},
})

export default theme
