import globalTheme from '@bluheadless/ui/src/theme/default'

const IconButton = {
	MuiIconButton: {
		styleOverrides: {
			root: {
				padding: '22px',
				'&.Mui-disabled': {
					backgroundColor: 'unset',
					color: 'var(--color-grey-30)',
				},
				'&.iconClose': {
					position: 'absolute',
					padding: 10,
					zIndex: 2,
				},
			},
			colorPrimary: {
				backgroundColor: 'var(--button-primary-bgcolor)',
				color: 'var(--button-primary-color)',
				'&:hover, :focus, :active': {
					color: 'var(--button-primary-hover-color)',
					backgroundColor: 'var(--button-primary-hover-bgcolor)',
					borderColor: 'var(--button-primary-hover-color)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey-30)',
				},
				'& .MuiCircularProgress-root': {
					color: 'var(--button-primary-color)',
				},
				'& .MuiSvgIcon-root': {
					color: 'inherit',
				},
			},
			colorSecondary: {
				backgroundColor: 'var(--button-secondary-bgcolor)',
				color: 'var(--button-secondary-color)',
				'&:hover, :focus, :active': {
					color: 'var(--button-secondary-hover-color)',
					backgroundColor: 'var(--button-secondary-hover-bgcolor)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey-30)',
				},
				'& .MuiCircularProgress-root': {
					color: 'var(--button-secondary-color)',
				},
				'& .MuiSvgIcon-root': {
					color: 'inherit',
				},
			},
			sizeMedium: {
				'& .MuiSvgIcon-root': {
					fontSize: globalTheme.typography.pxToRem(24),
				},
			},
			sizeLarge: {
				'& .MuiSvgIcon-root': {
					fontSize: globalTheme.typography.pxToRem(32),
				},
			},
		},
	},
}

export default IconButton
