const Input = {
	MuiInputBase: {
		styleOverrides: {
			root: {
				'&.Mui-focused': {
					input: {
						// override accessibility focus outline
						// cursor is set to the default text cursor
						outline: 'none !important',
					},
					textarea: {
						outline: 'none !important',
					},
				},
			},
		},
	},
}

export default Input
