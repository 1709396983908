const Checkbox = {
	MuiCheckbox: {
		styleOverrides: {
			root: {
				'&.Mui-focusVisible': {
					outline: 'var(--accessibility-focus-outline)',
				},
			},
		},
	},
}

export default Checkbox
